<template>
  <div>
    <van-nav-bar
      title="添加养殖"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-form @submit="onSubmit">
      <!-- 养殖类型 -->
      <van-field
        v-model="cascaderValue"
        is-link
        readonly
        label="选择养殖"
        placeholder="选择养殖类型"
        @click="showCategory = true"
      />
      <van-popup v-model="showCategory" round position="bottom">
        <van-cascader
          v-model="form.animal_category"
          title="选择畜禽"
          :options="ValueOptions"
          @close="showCategory = false"
          @finish="animal_typeOnFinish"
          @change="animal_typeOnChange"
          :field-names="fieldNames"
        />
      </van-popup>
      <!-- 选择牲畜 -->
      <van-field
        v-model="animal_typeValue"
        is-link
        readonly
        label="选择畜禽"
        placeholder="请选择畜禽"
        @click="showAnimal_type = true"
      />
      <van-popup v-model="showAnimal_type" round position="bottom">
        <van-cascader
          v-model="form.animal_type"
          title="请选择畜禽"
          :options="animal_typeOptions"
          @close="showAnimal_type = false"
          @change="showAnimal_typeOnChange"
          @finish="showAnimal_typeOnFinish"
          :field-names="fieldNames"
        />
      </van-popup>
      <!-- 选择种类 -->
      <van-field
        v-model="animal_smalltypeValue"
        is-link
        readonly
        label="选择种类"
        placeholder="请选择畜禽的种类"
        @click="showAnimal_smalltype = true"
      />
      <van-popup v-model="showAnimal_smalltype" round position="bottom">
        <van-cascader
          v-model="form.animal_smalltype"
          title="请选择畜禽的种类"
          :options="animal_smalltypeOptions"
          @close="showAnimal_smalltype = false"
          @change="animal_smalltypeOnChange"
          @finish="animal_smalltypeOnFinish"
          :field-names="fieldNames"
        />
      </van-popup>
      <!-- 选择品种 -->
      <van-field
        v-model="animal_varietyValue"
        is-link
        readonly
        label="选择品种"
        placeholder="请选择品种"
        @click="showAnimal_variety = true"
      />
      <van-popup v-model="showAnimal_variety" round position="bottom">
        <van-cascader
          v-model="form.animal_variety"
          title="请选择畜禽的品种"
          :options="animal_varietyOptions"
          @close="showAnimal_variety = false"
          @change="animal_varietyOnChange"
          @finish="animal_varietyOnFinish"
          :field-names="fieldNames"
        />
      </van-popup>

      <!-- 养殖条件 -->
      <van-field
        v-model="breed_conditionValue"
        is-link
        readonly
        label="养殖条件"
        placeholder="请选择养殖条件"
        @click="showBreed_condition = true"
        :rules="[{ required: true, message: '养殖条件为必填项' }]"
      />
      <van-popup v-model="showBreed_condition" round position="bottom">
        <van-cascader
          v-model="form.breed_condition"
          title="请选择养殖条件"
          :options="breed_conditionOptions"
          @close="showBreed_condition = false"
          :field-names="fieldNames"
          @finish="breed_conditionOnFinish"
        />
      </van-popup>
      <!-- 养殖类型 -->
      <van-field
        v-model="breed_categoryValue"
        is-link
        readonly
        label="养殖类型"
        placeholder="请选择养殖类型"
        @click="showBreed_category = true"
        :rules="[{ required: true, message: '养殖类型为必填项' }]"
      />
      <van-popup v-model="showBreed_category" round position="bottom">
        <van-cascader
          v-model="form.breed_category"
          title="请选择养殖类型"
          :options="breed_categoryOptions"
          @close="showBreed_category = false"
          :field-names="fieldNames"
          @finish="breed_categoryOnFinish"
        />
      </van-popup>
      <!-- 养殖方式 -->
      <van-field
        v-model="breed_wayValue"
        is-link
        readonly
        label="养殖方式"
        placeholder="请选择养殖方式"
        @click="showBreed_way = true"
        :rules="[{ required: true, message: '养殖方式为必填项' }]"
      />
      <van-popup v-model="showBreed_way" round position="bottom">
        <van-cascader
          v-model="form.breed_way"
          title="请选择养殖类型"
          :options="breed_wayOptions"
          @close="showBreed_way = false"
          :field-names="fieldNames"
          @finish="breed_wayOnFinish"
        />
      </van-popup>
      <!-- 入栏数量 -->
      <van-field
        v-model="form.num"
        label="入栏数量"
        placeholder="请输入入栏数量"
        :rules="[{ required: true, message: '请输入入栏数量' }]"
      />
      <!-- 入栏时间 -->
      <van-field
        v-model="form.enter_date"
        is-link
        :value="form.enter_date"
        label="入栏时间"
        placeholder="请选择入栏时间"
        @click="dayShow = true"
        :rules="[{ required: true, message: '入栏时间为必填项' }]"
      />
      <van-calendar
        v-model="dayShow"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="onConfirm"
      />
      <!-- 出栏天数 -->
      <van-field
        v-model="form.plant_out_days"
        label="计划出栏天数"
        placeholder="请输入出栏天数"
        :rules="[{ required: true, message: '出栏天数为必填项' }]"
      />
      <div style="margin: 16px">
        <van-button round block type="info" native-type="submit"
          >添加</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { category, categorys, postAnimalSave, getAnimalSmalltype, getAnimalSmalltypeId } from "../../service/AddBreeding";
import {
  NavBar,
  Form,
  Field,
  Popup,
  Cascader,
  Button,
  calendar,
  Notify
} from "vant";
export default {
  name: "",
  props: ["id"],
  components: {
    "van-nav-bar": NavBar,
    "van-form": Form,
    "van-field": Field,
    "van-popup": Popup,
    "van-cascader": Cascader,
    "van-button": Button,
    "van-calendar": calendar
  },
  data() {
    return {
      form: {
        animal_category: "", // 养殖类型
        animal_smalltype: "",
        animal_type: "", // 养殖种类
        animal_variety: "", // 养殖品种
        breed_category: "", //
        breed_condition: "", // 养殖条件
        breed_way: "", // 养殖方式
        enter_date: "", //
        plant_out_days: "", // 出栏天数
        pen_id: "",
        id: ""
      },
      // options的配置
      fieldNames: {
        text: "name",
        value: "id",
        children: []
      },
      dayShow: false,
      // 养殖类型
      cascaderValue: "",
      showCategory: false,
      ValueOptions: [],
      // 养殖
      animal_typeValue: "",
      showAnimal_type: false,
      animal_typeOptions: [],
      // 养殖种类
      animal_smalltypeValue: "",
      showAnimal_smalltype: false,
      animal_smalltypeOptions: [],
      // 养殖类型
      breed_categoryValue: "",
      showBreed_category: false,
      breed_categoryOptions: [],
      // 养殖品种
      animal_varietyValue: "",
      showAnimal_variety: false,
      animal_varietyOptions: [],
      // 养殖条件
      breed_conditionValue: "",
      showBreed_condition: false,
      breed_conditionOptions: [],
      // 养殖类型
      animal_categoryValue: "",
      showAnimal_category: false,
      animal_categoryOptions: [],
      // 养殖方式
      breed_wayValue: "",
      showBreed_way: false,
      breed_wayOptions: [],
      minDate: new Date(2020, 0, 1),
      maxDate: new Date()
    };
  },
  methods: {
    // 返回上一层
    onClickLeft() {
      this.$router.go(-1);
    },
    // 时间
    formatDate(date) {
      return `${date.getMonth() + 1}/${date.getDate()}`;
    },
    onConfirm(date) {
      this.dayShow = false;
      this.form.enter_date = dayjs(date).format("YYYY-MM-DD");
    },
    async getFieldValue() {
      const res = await category("animal_category");
      this.ValueOptions = res.data;
    },
    // 当值变化出发
    animal_typeOnChange({ value, selectedOptions, tabIndex }) {
      categorys(value).then((res) => {
        this.animal_typeOptions = res.data;
      });
      this.animal_typeValue = ""
      this.animal_smalltypeValue = ""
      this.animal_varietyValue = ""
    },
    // 全部选项选择完成后触发
    animal_typeOnFinish({ selectedOptions }) {
      this.showCategory = false;
      this.cascaderValue = selectedOptions
        .map((option) => option.name)
        .join("/");
    },
    // 第二项
    showAnimal_typeOnChange({ value, selectedOptions, tabIndex }) {
      getAnimalSmalltype(value).then((res) => {
        this.animal_smalltypeOptions = res.data;
      });
      this.animal_smalltypeValue = ""
      this.animal_varietyValue = ""
    },
    showAnimal_typeOnFinish({ selectedOptions }) {
      this.showAnimal_type = false;
      this.animal_typeValue = selectedOptions
        .map((option) => option.name)
        .join("/");
    },
    // 第三项
    animal_smalltypeOnChange({ value, selectedOptions, tabIndex }) {
      getAnimalSmalltypeId(value).then((res) => {
        this.animal_varietyOptions = res.data;
      });
      this.animal_varietyValue = ""
    },
    animal_smalltypeOnFinish({ selectedOptions }) {
      this.showAnimal_smalltype = false;
      this.animal_smalltypeValue = selectedOptions
        .map((option) => option.name)
        .join("/");
    },
    // 第三项
    animal_varietyOnChange() {},
    animal_varietyOnFinish({ selectedOptions }) {
      this.showAnimal_variety = false;
      this.animal_varietyValue = selectedOptions
        .map((option) => option.name)
        .join("/");
      this.breed_conditionList("breed_condition");
      this.breed_categoryList("breed_category");
      this.breed_wayList("breed_way");
    },
    // 养殖条件
    async breed_conditionList(value) {
      const res = await category(value);
      this.breed_conditionOptions = res.data;
    },
    breed_conditionOnFinish({ selectedOptions }) {
      this.showBreed_condition = false;
      this.breed_conditionValue = selectedOptions
        .map((option) => option.name)
        .join("/");
    },
    async breed_categoryList(value) {
      const res = await category(value);
      this.breed_categoryOptions = res.data;
    },
    // 养殖类型选完之后触发
    breed_categoryOnFinish({ selectedOptions }) {
      this.showBreed_category = false;
      this.breed_categoryValue = selectedOptions
        .map((option) => option.name)
        .join("/");
    },
    async breed_wayList(value) {
      const res = await category(value);
      this.breed_wayOptions = res.data;
    },
    breed_wayOnFinish({ selectedOptions }) {
      this.showBreed_way = false;
      this.breed_wayValue = selectedOptions
        .map((option) => option.name)
        .join("/");
    },
    // 表单提交
    async onSubmit(values) {
      const id = this.id;
      this.form.pen_id = id;
      const res = postAnimalSave(this.form);
      console.log(res);
      Notify({ type: "success", message: "添加成功" });
      this.$router.go(-1);
    }
  },
  mounted() {
    // 获取
    this.getFieldValue();
  },
  watch: {},
  computed: {},
  filters: {}
};
</script>

<style scoped lang='scss'>
</style>